// parse time from timetoken
export function parseTimetoken(timestamp: string | number | undefined) {
	if (!timestamp) return null;
	const parse =
		typeof timestamp === "number" ? timestamp : Number.parseInt(timestamp);
	const waktu = parse / 1e4;
	const notificationTime = new Date(waktu);
	return notificationTime.toISOString();
}

// for startTime class
export function isStartTimeBeforeNow(startTime: string) {
	if (!startTime) return null;
	const itemStartTime = new Date(startTime);
	const currentTime = new Date();

	//return itemStartTime < currentTime;
	return currentTime >= itemStartTime;
}

// for endTime class absen
export function isEndTimePassed(endTime: string) {
	if (!endTime) return null;
	const currentTime = new Date();
	const endTimeDate = new Date(endTime);

	return currentTime > endTimeDate;
}

// parse date
export function formatTimeAndDate(isoDate: any) {
	if (!isoDate) {
		return {
			daydate: "Data tidak valid",
			date: "Data tidak valid",
			time: "Data tidak valid",
		};
	}

	const date = new Date(isoDate);

	if (isNaN(date.getTime())) {
		return {
			daydate: "Data tidak valid",
			date: "Data tidak valid",
			time: "Data tidak valid",
		};
	}

	const formatDateDay = date.toLocaleDateString("id-ID", {
		weekday: "long",
		year: "numeric",
		month: "long",
		day: "numeric",
	});
	const formatTimeAndDate = date.toLocaleDateString("id-ID", {
		day: "numeric",
		month: "long",
		year: "numeric",
	});

	const formatHour = date.toLocaleTimeString("id-Id", {
		hour: "2-digit",
		minute: "2-digit",
		timeZone: "Asia/Jakarta",
		hour12: false,
	});

	const result = {
		daydate: formatDateDay, //Rabu, 27 Juli 2022
		date: formatTimeAndDate, // 27 Juli 2022
		time: formatHour, // 10.30
	};

	return result;
}

export function formatTimetoken(
	timetoken: string,
	timedate: boolean = false,
	time: boolean = false,
) {
	const parse = parseTimetoken(timetoken);
	const result = formatTimeAndDate(parse);

	if (timedate) {
		return `${result.date} ${result.time}`;
	} else if (time) {
		return result.time;
	} else {
		return result.date;
	}
}

export function formatTimeHourOrDate(timestamp: any) {
	if (!timestamp) return null;
	const parse = parseInt(timestamp);
	const waktu = parse / 1e4;
	const currentTime = new Date();
	const notificationTime = new Date(waktu);

	const timeDifference: any =
		currentTime.getTime() - notificationTime.getTime();
	const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

	if (hoursDifference < 24) {
		const formattedTime = notificationTime.toLocaleTimeString("id-ID", {
			hour: "2-digit",
			minute: "2-digit",
		});
		return formattedTime;
	} else {
		const formattedDate = notificationTime.toLocaleDateString("id-ID", {
			day: "numeric",
			month: "short",
		});
		return formattedDate;
	}
}

export const compareTimes = (
	timeStr1: string | number | Date,
	timeStr2: string | number | Date,
) => {
	const time1 = new Date(timeStr1);
	const time2 = new Date(timeStr2);

	const hour1 = time1.getHours();
	const minute1 = time1.getMinutes();

	const hour2 = time2.getHours();
	const minute2 = time2.getMinutes();

	const totalMinutes1 = hour1 * 60 + minute1;
	const totalMinutes2 = hour2 * 60 + minute2;

	if (totalMinutes1 < totalMinutes2) {
		return 1; // Tidak telat
	} else if (totalMinutes1 === totalMinutes2) {
		return 1; // Sama
	} else {
		return 2; // Telat
	}
};

export function isWithinTimeRange(
	startTime: string | number | Date,
	endTime: string | number | Date,
) {
	const currentTime = new Date();
	const startDateTime = new Date(startTime);
	const endDateTime = new Date(endTime);

	return currentTime >= startDateTime && currentTime <= endDateTime;
}
